/* eslint-disable operator-linebreak */
import { StyledFlexRow } from 'common/styles/common.styles';
import React, { useEffect, useState } from 'react';
import { KEEPERS, OFFCHAIN_KEEPERS_ADDRESS } from 'constants/constants';
import { ChainId } from '@ichidao/ichi-sdk/dist/src/crypto/networks';
import { ethers } from 'ethers';

type Props = {
  chainId: ChainId;
  chainName: string;
};

const KeeperBalance: React.FC<Props> = (props) => {
  const chainId = props.chainId;
  const [balance, setBalance] = useState('0');
  const [controllerBalance, setControllerBalance] = useState('0');
  
  const address = OFFCHAIN_KEEPERS_ADDRESS;
  const VAULT_CONTROLLER_ADDRESS = '0x8514B2Fa2889F7A2f46F1AA66b514999F2a7327F';
  const keeper = KEEPERS[chainId];

  useEffect(() => {
    async function getBalance() {
      if (keeper){
        try {
          const provider = new ethers.providers.JsonRpcProvider(keeper.providerUrl);
          provider?.getBalance(address)
            .then((bal) => {
              // convert a currency unit from wei to token
              setBalance(ethers.utils.formatEther(bal));
            })
            .catch(
              () => console.error(`Could not get keeper balance for chain ${chainId}`)
            );
        } catch (error) {
          console.error(`Could not get a provider for chain ${chainId}`);
        }
      }
    }
    getBalance();
  }, []);

  useEffect(() => {
    async function getControllerBalance() {
      if (chainId === ChainId.Hedera && keeper){
        try {
          const provider = new ethers.providers.JsonRpcProvider(keeper.providerUrl);
          provider?.getBalance(VAULT_CONTROLLER_ADDRESS)
            .then((bal) => {
              // convert a currency unit from wei to token
              setControllerBalance(ethers.utils.formatEther(bal));
            })
            .catch(
              () => console.error(`Could not get controller balance for chain ${chainId}`)
            );
        } catch (error) {
          console.error(`Could not get a provider for chain ${chainId}`);
        }
      }
    }
    getControllerBalance();
  }, []);

  const balanceTextColor = parseFloat(balance) < keeper.threshold ? 'alert-text-color' : '';
  const controllerBalanceTextColor = parseFloat(controllerBalance) < keeper.threshold ? 'alert-text-color' : '';

  return (<>
    <StyledFlexRow className=' primary-text-color' justifyContent='space-between' alignItems='center' marginTop='10px' style={{borderBottom: '1px solid #cccccc'}}>
      <span>{props.chainName}:</span>
      <div> 
        <span className={balanceTextColor}>{parseFloat(balance).toFixed(4)} {keeper?.tokenName} </span> 
        / {keeper.threshold} {keeper?.tokenName}
      </div>   
    </StyledFlexRow>
    {chainId === ChainId.Hedera && (
      <StyledFlexRow className=' primary-text-color' justifyContent='space-between' alignItems='center' marginTop='10px' style={{borderBottom: '1px solid #cccccc'}}>
        <span>{props.chainName} Controller:</span>
        <div> 
          <span className={controllerBalanceTextColor}>{parseFloat(controllerBalance).toFixed(4)} {keeper?.tokenName} </span> 
          / {keeper.threshold} {keeper?.tokenName}
        </div>   
      </StyledFlexRow>
    )}
  </>);
};

export default KeeperBalance;
