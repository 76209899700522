import React from 'react';
import KeepersContainer from './KeepersContainer';
import StrategiesContainer from './StrategiesContainer';

const HomeContainer: React.FC = () => {
  return (
    <>
      <KeepersContainer />
      <h2 className='mt-30 primary-text-color'>Paused Strategies</h2>
      <StrategiesContainer pausedOnly={true} />
    </>
  );
};

export default HomeContainer;
