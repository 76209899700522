import KeeperBalance from 'common/components/KeeperBalance';
import { KEEPERS } from 'constants/constants';
import React from 'react';

type Props = {
};

const KeepersContainer: React.FC<Props> = (props) => {
  return (
    <>
      <h2 className=' primary-text-color'>Offchain AWS Keepers</h2>
      {Object.values(KEEPERS).sort((a, b) => b.chainName < a.chainName ? 1 : -1)
      .map((k) =>(
        <KeeperBalance key={k.chainId} chainId={k.chainId} chainName={k.chainName} />
      ))}
    </>
  );
};

export default KeepersContainer;
