import App from 'App';
import HomeContainer from 'containers/HomeContainer';
import React from 'react';

const Index: React.FC<{}> = () => {
  return (
    <App>
      <HomeContainer />
    </App>
  );
};

export default Index;
